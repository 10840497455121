const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://8rkf5irh05.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://sjc02ugwve.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.debmarine.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://tlqypz0zw1.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;